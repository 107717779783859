var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-alert',{staticClass:"text-center elevation-15",attrs:{"color":"blue-grey","dark":"","outlined":"","dense":""}},[_vm._v(" Začiatok volieb: "),_c('strong',[_vm._v(_vm._s(_vm.getSettings.volby_zaciatok))]),_vm._v(". Koniec volieb: "),_c('strong',[_vm._v(_vm._s(_vm.getSettings.volby_koniec))]),_vm._v(". "),(_vm.user)?_c('span',[_vm._v(" Tvoj celkový počet hlasov: "),_c('strong',[_vm._v(_vm._s(_vm.user.votes_given + _vm.user.votes_available))]),_vm._v(". Použil si: "),_c('strong',[_vm._v(_vm._s(_vm.user.votes_given))]),_vm._v(". Zostáva Ti: "),_c('strong',[_vm._v(_vm._s(_vm.user.votes_available))]),_vm._v(".")]):_vm._e()]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('data-table-paging-filtering',{attrs:{"headers":_vm.headers,"get-items-func":_vm.getItems,"item-row-background-func":_vm.itemRowBackground,"show-expand":true,"dense":false,"sync":_vm.sync,"searchEnabled":true,"uniqueID":"listtable"},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',{staticClass:"ma-1"},[_c('v-col',{attrs:{"cols":"12","lg":"4","md":"6","sm":"12","xs":"12","align":"center"}},[_c('v-img',{attrs:{"contain":"","max-width":"300","src":item.photo}}),_c('read-more-text',{attrs:{"length":200,"text":item.short_info}})],1),_c('v-col',{attrs:{"cols":"12","lg":"4","md":"6","sm":"12","xs":"12","align":"center"}},[_c('h4',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(item.candidate.user.first_name)+" "+_vm._s(item.candidate.user.last_name)+" - videosľub kandidáta na poslanca do NR SR za Naše Hnutie ")]),_c('video',{attrs:{"width":"320","controls":""}},[_c('source',{attrs:{"src":item.video,"type":"video/mp4"}}),_vm._v(" Your browser does not support the video tag. ")])]),_c('v-col',{attrs:{"cols":"12","lg":"4","md":"6","sm":"12","xs":"12"}},[_c('h4',[_vm._v("Informácie o kandidátovi:")]),_c('p',[_vm._v(" Meno: "+_vm._s(item.candidate.user.first_name)+" "),_c('br'),_vm._v(" Priezvisko: "+_vm._s(item.candidate.user.last_name)+" ")]),_c('read-more-text',{attrs:{"length":1000,"text":item.long_info}})],1)],1)],1)]}},{key:"item.voted",fn:function(ref){
var item = ref.item;
return [(item.can_user_vote && !item.voted)?_c('v-btn',{staticClass:"ml-1",attrs:{"color":"green lighten-2","small":"","outlined":""},on:{"click":function($event){return _vm.vote(item)}}},[_vm._v(" Dať hlas ")]):_vm._e(),(item.voted)?_c('v-btn',{staticClass:"ml-1",attrs:{"color":"red lighten-2","small":"","outlined":""},on:{"click":function($event){return _vm.unvote(item)}}},[_vm._v(" Odobrať ")]):_vm._e()]}},{key:"item.favorite",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.favorite)?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"amber amber-darken-4","dark":""},on:{"click":function($event){return _vm.removeFavorite(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-star ")]):_c('v-icon',_vm._g(_vm._b({attrs:{"color":"amber amber-darken-4","dark":""},on:{"click":function($event){return _vm.addFavorite(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-star-outline ")])]}}],null,true)},[_c('span',[_vm._v("Favorit")])])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }