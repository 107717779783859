<template>
  <div>
    <v-alert
      color="blue-grey"
      dark
      outlined
      dense
      class="text-center elevation-15"
    > Začiatok volieb: <strong>{{ getSettings.volby_zaciatok }}</strong>. Koniec volieb: <strong>{{ getSettings.volby_koniec }}</strong>.
      <span v-if="user">
        Tvoj celkový počet hlasov:
        <strong>{{ user.votes_given + user.votes_available }}</strong>. Použil si: <strong>{{ user.votes_given }}</strong>. Zostáva Ti: <strong>{{ user.votes_available }}</strong>.</span>
    </v-alert>

    <v-row>
      <v-col cols="12">
        <v-card>
          <data-table-paging-filtering
            :headers="headers"
            :get-items-func="getItems"
            :item-row-background-func="itemRowBackground"
            :show-expand="true"
            :dense="false"
            :sync="sync"
            :searchEnabled="true"
            uniqueID="listtable"
          >
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-row class="ma-1">
                  <v-col
                    cols="12"
                    lg="4"
                    md="6"
                    sm="12"
                    xs="12"
                    align="center"
                  >
                    <v-img
                      contain
                      max-width="300"
                      :src="item.photo"
                    />
                    <read-more-text
                      :length="200"
                      :text="item.short_info"
                    ></read-more-text>
                  </v-col>
                  <v-col
                    cols="12"
                    lg="4"
                    md="6"
                    sm="12"
                    xs="12"
                    align="center"
                  >
                    <h4 class="mb-1">
                      {{ item.candidate.user.first_name }}
                      {{ item.candidate.user.last_name }} - videosľub kandidáta na
                      poslanca do NR SR za Naše Hnutie
                    </h4>

                    <video
                      width="320"
                      controls
                    >
                      <source
                        :src="item.video"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </v-col>
                  <v-col
                    cols="12"
                    lg="4"
                    md="6"
                    sm="12"
                    xs="12"
                  >
                    <h4>Informácie o kandidátovi:</h4>
                    <p>
                      Meno: {{ item.candidate.user.first_name }} <br />
                      Priezvisko: {{ item.candidate.user.last_name }}
                    </p>
                    <read-more-text
                      :length="1000"
                      :text="item.long_info"
                    ></read-more-text>
                  </v-col>
                </v-row>
              </td>
            </template>

            <template v-slot:item.voted="{ item }">
              <v-btn
                v-if="item.can_user_vote && !item.voted"
                @click="vote(item)"
                color="green lighten-2"
                small
                outlined
                class="ml-1"
              >
                Dať hlas
              </v-btn>

              <v-btn
                v-if="item.voted"
                @click="unvote(item)"
                color="red lighten-2"
                small
                outlined
                class="ml-1"
              >
                Odobrať
              </v-btn>
            </template>

            <template v-slot:item.favorite="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-if="item.favorite"
                    color="amber amber-darken-4"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    @click="removeFavorite(item)"
                  >
                    mdi-star
                  </v-icon>
                  <v-icon
                    v-else
                    color="amber amber-darken-4"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    @click="addFavorite(item)"
                  >
                    mdi-star-outline
                  </v-icon>
                </template>
                <span>Favorit</span>
              </v-tooltip>
            </template>
          </data-table-paging-filtering>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>


<script>
import { APIService } from "@/http/APIService.js";
import DataTablePagingFiltering from "@/components/DataTablePagingFiltering.vue";
import ReadMoreText from "@/components/ReadMoreText.vue";
import { mapGetters } from "vuex";

const apiService = new APIService();

export default {
  components: {
    DataTablePagingFiltering,
    ReadMoreText,
  },

  data: () => ({
    item: undefined,
    user: null,
    sync: false,

    headers: [
      { text: "Poradie", value: "rank" },
      {
        text: "Priezvisko",
        value: "candidate.user.last_name",
        model: "user__last_name",
      },
      {
        text: "Meno",
        value: "candidate.user.first_name",
        model: "user__first_name",
      },
      {
        text: "Mesto/Obec",
        value: "candidate.user.address_town",
        model: "user__address_town",
      },
      {
        text: "Okres",
        value: "candidate.user.address_okres",
        model: "user__address_okres",
      },
      { text: "Počet hlasov", value: "votes" },
      { text: "Favorit", value: "favorite" },
      { text: "Hlasovanie", value: "voted" },
    ],
  }),

  computed: {
    ...mapGetters("settings", ["getSettings"]),
  },

  mounted: function () {
    this.refreshItems();
  },

  methods: {
    itemRowBackground: function (item) {
      return item.voted == true ? "success lighten-5" : "";
    },

    addFavorite(item) {
      apiService.addFavoriteKandidat(item.id).then((response) => {
        this.sync = !this.sync;
        this.refreshItems();
      });
    },

    removeFavorite(item) {
      apiService.removeFavoriteKandidat(item.id).then((response) => {
        this.sync = !this.sync;
        this.refreshItems();
      });
    },

    getItem(id) {
      apiService.getKandidat(id).then((response) => {
        this.sync = !this.sync;
      });
    },

    vote(item) {
      apiService.voteKandidat(item.id).then((response) => {
        this.sync = !this.sync;
        this.refreshItems();
      });
    },

    unvote(item) {
      apiService.unvoteKandidat(item.id).then((response) => {
        this.sync = !this.sync;
        this.refreshItems();
      });
    },

    refreshItems() {
      this.getMe();
    },

    getItems(sort, search, limit, offset, filters) {
      return apiService.getKandidats(sort, search, limit, offset, filters);
    },

    getMe() {
      apiService.getMe().then((response) => {
        this.user = response;
      });
    },
  },
};
</script>